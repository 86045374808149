.App {
  text-align: center;
  width: 100%;
  height: 100vh;

}
html body .App{
  margin: 0;
  padding: 0;
  min-width: 1180px;
}
html body::-webkit-scrollbar{
  width: 0;
}
.header{
  width: 100%;
  height: 440px;
  background-image: url("../public/img/headerbg.png");
  background-size: 100% 100%;
}
.title{
  font-size: 50px;
  font-family: Milky-Han-Mono-CN-Heavy, Milky-Han-Mono-CN;
  font-weight: 800;
  color: #FFFFFF;
  height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bubble{
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bubblecontent{
  display: flex;
  width: 850px;
}
.bubbleItem{
  padding: 4px 8px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin-right: 24px;
  cursor: pointer;
}
.abubblective{
  color:#003CDC ;
  background: #FFFFFF;
}
.abubblective::after{
  content: '';
  position: absolute;
  bottom: -15px;
  left: 60%;
  border: 8px solid transparent;
  border-top-color: #fff ;

}
.bubbleItem:hover{
  color:#003CDC ;
  background: #FFFFFF;

}
.bubbleItem:hover::after{
  content: '';
  position: absolute;
  bottom: -15px;
  left: 60%;
  border: 8px solid transparent;
  border-top-color: #fff ;

}
.hotsearch{

  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBox{
 display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
}
.searchBoxMain{
  width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.serchBtn{
  width: 115px;
  height: 50px;
  background: #FDCF01;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  user-select: none;
  border-radius: 0px 8px 8px 0px;
}
.ant-input{
  height: 50px !important;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
}
.hotSearchleft img{
  width: 100%;height: 100%;
}

.hotSearch{
  flex: 1;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 50px;
}
.hotSearch::-webkit-scrollbar{
  width: 0;
}
.hotSearchItem{
  height: 40px;
  background: rgba(0,0,0,0.2);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 10px;
}
.hotSearchItemMain{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 23px;
  background: #0074d8b8;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.hotSearchleft{
  width: 72px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.cardBox{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background: #F8F8F8;
  box-sizing: border-box;
  padding: 20px;

}
.cardMian{
width:1180px ;
  height: 100%;
  display: flex;
  align-items: flex-start;justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 1180px
}
.cardItem{
  width: 224px;
  height: 91px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px #DFEEFE;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-right:15px ;
}
.cardItem:nth-child(5n){
  margin-right: 0;
}
.cardItem:nth-child(6){
  margin-bottom: 0;
}
.cardItem:nth-child(7){
  margin-bottom: 0;
}
.cardItem:nth-child(8){
  margin-bottom: 0;
}
.cardItem:nth-child(9){
  margin-bottom: 0;
}
.cardItem:nth-child(10){
  margin-bottom: 0;
}
.cardMian .icon{
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 3px;
  margin-right: 10px;
}
.cardMian .icon img{
  width: 100%;
  height: 100%;
}
.cardItem .content{
  text-align: left;
}
.cardItem .content .name{
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 33px;
}
.cardItem .content .describe{
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.4);
  line-height: 25px;
}
.question{
  width: 100%;
  height: 206px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;


}
.questionmiddle{
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.question .titless{
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0,0,0,0.6);
  line-height: 60px;

}
.question .title2{
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0,0,0,0.2);

}
.question .tabCard{
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question_main{
  width: 1180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.question_mainimg1{
  position: absolute;
  top: 30px;
  left: 17px;
  width: 46px;
  height: 46px;
  opacity: 0.38;
  animation: star  1.2s ease-in-out .5s infinite alternate;
}
.question_mainimg2{
  position: absolute;
  top: 60px;
  left: 140px;
  width: 90px;
  height: 90px;
  opacity: 0.38;
  animation: star  1.2s ease-in-out .5s infinite alternate;
}
.question_mainimg3{
  position: absolute;
  top: 30px;
  right: 17px;
  width: 46px;
  height: 46px;
  opacity: 0.38;
  animation: star  1.2s ease-in-out .5s infinite alternate;
}
@keyframes star {
  10% {
    opacity: 0.3;
  }

  90% {
    opacity: 0.8;
  }
}
.question_mainimg4{
  position: absolute;
  top: 60px;
  right: 140px;
  width: 90px;
  height: 90px;
  opacity: 0.38;
  animation: star  1.2s ease-in-out .5s infinite alternate;
}
.question .tabCard .tabItem{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
  cursor: pointer;
}
.questionListBox{
  width: 100%;
  background: #F8F8F8;
  display: flex;align-items: center;justify-content: center;
  padding:  24px 0;
  min-width: 1180px;
}
.questionList{
  width: 1180px;
}
.questionList .item{
  width:100%;
  height: 90px;
  background: #FFFFFF;
  padding-left: 54px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.questionList .item:last-child{
  margin-bottom: 0;
}
.questionList .item .itemName{
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 40px;
  color: rgba(0,0,0,0.8);
}
.questionList .item .itemdasc{
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.4);

  letter-spacing: 4px;
}
.footer{
width: 100%;
  height: 60px;
  background: #000000;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContent{
  margin-top: 20px;
  width: 450px;
  height: 450px;
  margin-left:10px ;
  display: flex;
  align-items: center;justify-content: center;
  flex-direction: column;
}
.modalContent .code{
  width: 244px;
  height: 244px;
}
.titleee{
  font-size: 25px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 42px;
  margin-bottom: 20px;
}
.modalContent  .desc{
  width: 254px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0,0,0,0.4);
  line-height: 30px;
  letter-spacing: 5px;
  text-align: center;
}


